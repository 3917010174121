import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const styles = {
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2,
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`,
    },
  },
  link: {
    color: '#4CAF50',
    textDecoration: 'none',
  },
}

const Commitment = props => (
  <Section aside title='Pre-flight check' {...props}>
    <Card variant='paper'>
      <Text as='ul' variant='small' sx={styles.list}>
        When reaching out to us:
        <li>Take a look at our <a style={styles.link} href="https://forum.honeyside.it/" target="_blank">Forum</a>. Maybe your question has already been answered!</li>
        <li>Explain the issue that you are experiencing with as much detail as you can.</li>
        <li>Attach your license or subscription number.</li>
      </Text>
    </Card>
  </Section>
)

export default Commitment
